.text-dr-blu {
  color: #006dc2;
}

.main-logo {
  max-width: 150px;
  width: 100%;
}
.dashboard-cd-amot {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}
.dashboard-cd-blc {
  font-size: 14px;
}
.dashboard-cd-icon {
  font-size: 50px;
}
.bg-cr-1 {
  background-color: #0394fd;
}
.bg-cr-2 {
  background-color: #006dc2;
}
.bg-cr-3 {
  background-color: #b2dabf;
}
.dash-sub-list-heading h4 {
  font-size: 18px;
  display: inline-block;
}
.sub-lable {
  font-size: 13px;
  padding: 3px 15px;
  border-radius: 30px;
  color: #ffffff;
}
.subs-main-crd {
  border-bottom: 1px solid #d8d8d8;
  padding: 1.25rem;
}
.subs-main-crd p {
  font-size: 13px;
  color: #424242;
  margin-bottom: 5px;
}
.sub-progress {
  width: 150px;
  float: right;
}
.sub-progress .progress-bar {
  background-color: #4d95fd;
}
.sub-progress .progress {
  background-color: #dbdcde;
}
.subs-main-scroll {
  height: 240px;
  overflow-y: auto;
}
.subs-main-scroll::-webkit-scrollbar {
  width: 4px;
  box-shadow: none;
  border-radius: 10px;
}
.subs-main-scroll::-webkit-scrollbar-thumb {
  background: #006dc2;
  box-shadow: none;
  border-radius: 10px;
  outline: none;
}
.subs-main-scroll::-webkit-scrollbar-track {
  background: #ccc;
  box-shadow: none;
  border-radius: 10px;
}
.progressbar-js-circle {
  position: relative;
  width: 42px;
  display: inline-block;
  float: right;
}
.on-going-pro-date {
  display: inline-block;
}
.pro-date {
  border: 1px solid #d2d2d2;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 11px;
  color: #376dc2 !important;
}
.pro-heading-b {
  position: relative;
  padding-left: 10px;
}
.pro-heading-b::before {
  position: absolute;
  left: -5px;
  top: 5px;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #72c0b2;
  border-radius: 50%;
}
.sub-dash-text {
  font-size: 18px;
}
/*umairs css-------------------------------------------------------------start*/

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
